import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-viewimage',
  templateUrl: './viewimage.component.html',
  styleUrls: ['./viewimage.component.scss']
})
export class ViewimageComponent implements OnInit {
  imageUrl:any=localStorage.getItem('imageurl');
  imagePath:any=localStorage.getItem('imagePath');
    constructor(private router: Router, private appComponent: AppComponent,) { }

  ngOnInit() {
  }
  goToBugDescription()
  {
      this.appComponent.startSpinner();
    this.router.navigate(['/bugdescription']);
  }
}
