<nav class="navbar navbar-default" style="height: 60px;">
    <div class="container-fluid">
        <div class="navbar-header">
            <button type="button" class="navbar-toggle" data-toggle="collapse" (click)="sidebarToggle()">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <a class="navbar-brand text-uppercase">{{getTitle()}}</a>
        </div>
        <div class="collapse navbar-collapse">
           

            <ul class="nav navbar-nav navbar-right">
                
                <li class="dropdown">
                    <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                        <p>
                            Welcome! <b>{{Name}}</b>
                            <b class="caret"></b>
                        </p>
                    </a>
                    <ul class="dropdown-menu" style="margin-top: -31px;margin-right: 14px;border-radius: -1px;">
                        <!-- <li><a href="#">Change Password</a></li>-->
                        <li><a [routerLink]="['/changepassword']" style="cursor: pointer;">Change Password</a></li>
                        <li><a (click)="logOut()" style="cursor: pointer;">Log out</a></li>

                    </ul>
                </li>
            </ul>
        </div>
    </div>
</nav>
