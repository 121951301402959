
<div class="col-md-12 body-content" style="left: 40%;margin-top:3%;" *ngIf="showFirstForm">

    <div class="col-lg-3 col-md-4 text-normal login-form">
        <div style="margin-top:12px;">

        </div>
        <div class="panel panel-default ">
            <div class="panel-heading login-txt"><strong><span style="padding-left: 1px;">Forgot Your Password?</span></strong> </div>
            <div class="panel-body">
                <form class="form-horizontal">
                    <div class="form-group">
                        <label for="password" class="col-md-12">
                            Enter your phone number
                            <span><strong style="font-size: 20px;color : red;">*</strong></span>
                        </label>
                        <div class="col-md-12">
                            <input type="text" class="form-control" name="phnumber" required id="phnumber" #phnumber="ngModel" [(ngModel)]="data.phnumber">
                            <div *ngIf="phnumber.invalid && (phnumber.dirty || phnumber.touched)" class="alert alert-danger">
                                <div *ngIf="phnumber.errors.required">
                                    Phone Number is required.
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="form-group">
                        <div class="col-md-12">
                            <input type="submit" class="btn btn-success btn-block btn-fill" value="Send SMS" [disabled]="phnumber.invalid" (click)="sendOtp(data)">
                            <h6 class="pull-left marginTop10" style="cursor: pointer;"><a [routerLink]="['/login']" class="pe-7s-back">Back</a></h6>

                        </div>
                    </div>
                </form>
            </div>
        </div>

    </div>
</div>

<div class="col-md-12 body-content" style="left: 40%;margin-top:3%;" *ngIf="showSecondForm">

    <div class="col-lg-3 col-md-4 text-normal login-form">
        <div style="margin-top:12px;">

        </div>
        <div class="panel panel-default ">
            <div class="panel-heading login-txt"><strong><span style="padding-left: 1px;">Verify your OTP</span></strong> </div>
            <div class="panel-body">
                <form class="form-horizontal">
                    <div class="form-group">
                        <label for="password" class="col-md-12">
                            Enter your OTP
                            <span><strong style="font-size: 20px;color : red;">*</strong></span>
                        </label>
                        <div class="col-md-12">
                            <input type="text" class="form-control" name="otp" required id="otp" #otp="ngModel" [(ngModel)]="data.otp">
                            <div *ngIf="otp.invalid && (otp.dirty || otp.touched)" class="alert alert-danger">
                                <div *ngIf="otp.errors.required">
                                    Please enter your OTP.
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="form-group">
                        <div class="col-md-12">
                            <input type="submit" class="btn btn-success btn-block btn-fill" value="Submit" [disabled]="otp.invalid" (click)="verifyOtp(data)">
                            <h6 class="pull-left marginTop10" style="cursor: pointer;"><a [routerLink]="['/login']" class="pe-7s-back">Back</a></h6>

                        </div>
                    </div>
                </form>
            </div>
        </div>

    </div>
</div>
<div class="col-md-12 body-content" style="left: 40%;margin-top:3%;" *ngIf="showThirdForm">

    <div class="col-lg-3 col-md-4 text-normal login-form">
        <div style="margin-top:12px;">

        </div>
        <div class="panel panel-default ">
            <div class="panel-heading login-txt"><strong><span style="padding-left: 1px;">Reset your password</span></strong> </div>
            <div class="panel-body">
                <form class="form-horizontal">
                    <div class="form-group">
                        <label for="cpassword" class="col-md-12">
                        New Password
                            <span><strong style="font-size: 20px;color : red;">*</strong></span>
                            <i style="font-size: 14px;" class="fa fa-info-circle" title=" Password should contain,  Minimum 6 Character,Atleast 1 Numeric Value ,  Atleast 1 Special Character,  Atleast 1 Small Letter,  Atleast 1 Capital Letter."></i>
                        </label>
                        <div class="col-md-12">
                            <input type="password" class="form-control form-control-sm float-left" id="Password"
                                   name="Password" #Password="ngModel" minlength="6"
                                   [(ngModel)]="data.Password" (ngModelChange)="getpassword($event)"
                                   pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
                                   tooltip="Password should contain,  Minimum 6 Character,  Atleast 1 Numeric Value ,  Atleast 1 Special Character,  Atleast 1 Small Letter,  Atleast 1 Capital Letter" placement="right" required>

                            <div *ngIf="Password.invalid && (Password.dirty || Password.touched)" class="alert alert-danger">
                                <div *ngIf="Password.errors.pattern">
                                    Password should contain,  Minimum 6 Character,  Atleast 1 Numeric Value ,  Atleast 1 Special Character,  Atleast 1 Small Letter,  Atleast 1 Capital Letter.
                                </div>
                                <div *ngIf="Password.errors.required"> Password is required. </div>
                            </div>

                        </div>
                    </div>
                    <div class="form-group">
                        <label for="cpassword" class="col-md-12">
                            Confirm Password
                            <span><strong style="font-size: 20px;color : red;">*</strong></span>
                            <i style="font-size: 14px;" class="fa fa-info-circle" title=" Password should contain,  Minimum 6 Character,Atleast 1 Numeric Value ,  Atleast 1 Special Character,  Atleast 1 Small Letter,  Atleast 1 Capital Letter."></i>
                        </label>
                        <div class="col-md-12">
                            <input type="password" class="form-control form-control-sm" id="confirmpassword"
                                   name="confirmpassword" #confirmpassword="ngModel" [(ngModel)]="data.confirmpassword"
                                   (ngModelChange)="checkSame($event)" required />
                            <div *ngIf="confirmpassword.invalid && (confirmpassword.dirty || confirmpassword.touched)" class="alert alert-danger">
                                <p *ngIf="confirmpassword.errors.required">
                                    Confirm Password is required
                                </p>
                            </div>
                            <p *ngIf="checkpassword" class="alert alert-danger">
                                Password and Confirm Password Not Matched
                            </p>


                        </div>
                    </div>


                    <div class="form-group">
                        <div class="col-md-12">
                            <input type="submit" class="btn btn-success btn-block btn-fill" value="Change" [disabled]="Password.invalid||confirmpassword.invalid" (click)="resetPassword(data)">
                            <h6 class="pull-left marginTop10" style="cursor: pointer;"><a [routerLink]="['/login']" class="pe-7s-back">Back</a></h6>

                        </div>
                    </div>
                </form>
            </div>
        </div>

    </div>
</div>
<div class="modal" id="message">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <h5 style="color:red;text-align:justify"><b>Your Password has been changed successfully. Please click ok to login.</b></h5>
                <button type="button" class="btn btn-success btn-fill" (click)="goToLoginPage()" id="okButton">OK</button>
            </div>
        </div>
    </div>
</div>