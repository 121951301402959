import { Component, OnInit } from '@angular/core';
import{Router, ActivatedRoute}from '@angular/router'
import { AppComponent } from '../app.component';
import { AuthenticationService } from 'app/services/authentication.service';
import { HttpClient } from '@angular/common/http';
declare const report:any;
declare var $:any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  homeContent: string;
  // logIn: boolean = true;
   loginError: boolean = false;
   logOut: boolean = false;
   defaultHome: boolean = true;
   sectorHiddenArray=new Array();
   userLogin: string;
   showDiv: boolean = false;
   showForget:boolean=false;
   showLogin:boolean=true;
   confirmPassword:boolean=false;
   tokens:any=undefined;
   storeToken:any;
   _login:any={};
   holdEmail: any = [];
   setNewPassword:any=[];
   checkToken:any;
   IsActive:boolean;
    showThankYouMessage: boolean;
   
  constructor(private router:Router,
    private authService:AuthenticationService,private appComponent:AppComponent,
      private route: ActivatedRoute, private _http: HttpClient) { 
      
  }
 
    ngOnInit() {
       
    
  this.route.queryParams.subscribe((params) => {
    this.tokens=params;
    if(this.tokens.token==undefined){
      this.confirmPassword=false;
      this.showForget=false;
      this.showLogin=true;      
    }
    else{
      this.authService.checkToken(this.tokens.token).subscribe(
        data => {
          this.checkToken=data;
          if(this.checkToken==0){
            this.appComponent.showNotification('bottom','right','Oops Link Has Been Expired!!','danger')
          // alert("Oops Link Has Been Expired!!");
            this.showLogin=true;
          }
          else{
            
            this.storeToken=this.tokens.token;
            this.showForget=false;
            this.showLogin=false;
            this.confirmPassword=true;
          }
        },
        (err)=>{
            this.appComponent.showNotification('bottom', 'right', err.Message, 'danger');
        });  
    }     
  });   
    this.logOut=false;
    //this.appComponent.logIn=true;
    this.defaultHome=true;
    }
    openmodel() {
        $("#message").css("display", "block");
        $("#message").css("margin-top", "15%");
        $(".body-content").css("filter", "blur(4px)");
        $(".bg").css("filter", "blur(4px)");
        $(".subhead").css("filter", "blur(4px)");
    }
    closemessage() {
        $("#message").css("display", "none");
        $(".bg").css("filter", "blur(0px)");
        $(".body-content").css("filter", "blur(0px)");
        $(".subhead").css("filter", "blur(0px)");
    }
  storeAdmin:any="admin";
  loginUser(e){ 
      let user;
      this.appComponent.startSpinner();
     var userName= e.target.elements[0].value;
     var password=e.target.elements[1].value;
       this.authService.login(userName,password).subscribe(
        data => {
               this.IsActive = data.isEnable;
               console.log(this.IsActive, data.emailConfirmed);
          if(!this.IsActive)
           {
              this.openmodel();
          }

          else {
              this.router.navigate(['home']);
          }
        },(err)=>{  
          this.loginError=true;         
           this.appComponent.stopSpinner();
               this.appComponent.showNotification('bottom', 'right', 'Error: User Name or Password Invalid.', 'danger');
        
         });
  }

showLogIn(){
  this.showForget=false;
  this.showLogin=true;
  this.confirmPassword=false;
}

showForgetPassword(){
  this.showLogin=false;
  this.showForget=true;
  this.confirmPassword=false;
}


}
