<div class="col-md-12 body-content" style="left: 40%;">

    <div class="col-lg-3 col-md-4 text-normal login-form">
        <div class="row">
            <div class="col-md-12 padding-left10 " style=" margin-top: 15px;">
                <span style="float: left;">
                    <strong style="font-size: 15px;color : red;
                            padding-left: 12px;">All the * marked fields are mandatory</strong>
                </span>
                <br><br>
            </div>
        </div>
        <div class="panel panel-default ">
            <div class="panel-heading login-txt"><span style="padding-left: 1px;font-size:20px;">LOGIN</span></div>
            <div class="panel-body">
                <form class="form-horizontal" role="form" (submit)="loginUser($event)">
                    <div class="form-group">
                        <label for="email" class="col-md-12">Email
                            <span><strong style="font-size: 20px;color : red;">*</strong></span></label>

                        <div class="col-md-12">
                            <input type="text" class="form-control" name="User" value="" id="User" required [(ngModel)]="_login.User" #User="ngModel">
                            <div *ngIf="User.invalid && (User.dirty || User.touched)" class="alert alert-danger">
                                <div *ngIf="User.errors.required">
                                    Email ID is required.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="password" class="col-md-12">PASSWORD
                            <span><strong style="font-size: 20px;color : red;">*</strong></span></label>
                        <div class="col-md-12">
                            <input type="password" class="form-control" name="password" required id="password" #password="ngModel" [(ngModel)]="_login.password">
                            <div *ngIf="password.invalid && (password.dirty || password.touched)" class="alert alert-danger">
                                <div *ngIf="password.errors.required">
                                    Password is required.
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="form-group">
                        <div class="col-md-12">
                            <input type="submit" class="btn btn-success btn-block btn-fill" value="Login" style="font-size:18px"
                                   [disabled]="User.invalid || password.invalid">
                            <h6 class="pull-right marginTop10" style="cursor: pointer;color:rgb(9, 212, 43);"><a [routerLink]="['/passwordmanagement']">Forgot Password</a></h6>


                        </div>
                        <!--<div class="col-md-12">
                            <p class="marginTop10">Don't have an account?<a [routerLink]="['/register']" style="cursor: pointer;color:rgb(9, 212, 43);"> Register here</a> </p>


                        </div>-->
                    </div>
                </form>
            </div>
        </div>

    </div>
</div>
