import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable } from 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class PasswordmanagementService {
   
    private apiUrl = localStorage.getItem('apiurl');
    constructor(private http: Http) { }

    private extractData(res: Response) {
        let body = res.json();
        return body || [];
    }

    private handleError(error: any) {
        let errMsg = error.json();
        console.error(errMsg); // log to console instead
        return Observable.throw(errMsg);
    }

    sendOtpService(parm: any) {
        localStorage.setItem("resetPhoneNumber", parm.phnumber);
        let resetPasswordUrl = this.apiUrl + "SendAndVerifyMobileOTP/sendOtpToMobile?MobileNumber="
            + parm.phnumber;
        return this.http.post(resetPasswordUrl, {
        }).catch(this.handleError)
    }
    verifyOtpService(resetPasswordParm:any) {
        let resetPasswordUrl = this.apiUrl + "SendAndVerifyMobileOTP/verifyOtpForMobile?otp=" +
            resetPasswordParm.otp + "&mobileNumber=" + localStorage.getItem("resetPhoneNumber");
        return this.http.get(resetPasswordUrl, {
        }).catch(this.handleError)
    }
    resetPasswordService(resetPasswordParm: any) {
        let resetPasswordUrl = this.apiUrl + "Account/resetpassword/" 
            + resetPasswordParm.Password + "/" + localStorage.getItem("resetPhoneNumber");
        return this.http.post(resetPasswordUrl, {
        }).catch(this.handleError)
    }
    changePasswordService(resetPasswordParm: any) {
        let resetPasswordUrl = this.apiUrl + "Account/ChangePassword/" + resetPasswordParm.registrationId
            + "/"  + resetPasswordParm.opassword
            + "/" + resetPasswordParm.npassword;
        return this.http.post(resetPasswordUrl, {
        }).catch(this.handleError)
    }

}
