import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HomeService } from '../home/home.service';
import { AppComponent } from '../app.component';
import { map, takeWhile } from 'rxjs/operators';
import { Observable } from 'rxjs';
declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/home', title: 'Home',  icon: 'pe-7s-graph', class: '' },
    { path: '/changepassword', title: 'Change Password', icon: 'fa fa-bug', class: '' },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  
    constructor(private HomeService: HomeService, private router: Router, private appComponent: AppComponent) { }

    ngOnInit() {
        //this.sub = Observable.interval(10000)
        //    .subscribe((val) => { this.getNewApplicationDetails(); });
    this.menuItems = ROUTES.filter(menuItem => menuItem);
     
      }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
}
