
<div class="wrapper">
  <div class="sidebar"  data-color="black" data-image="">
      <app-sidebar></app-sidebar>
      <div class="sidebar-background" style="background-image: url(assets/img/sidebar-4.jpg)"></div>
  </div>

  <div class="main-panel">
      <navbar-cmp></navbar-cmp>
      <router-outlet></router-outlet>
      <div>
          <footer-cmp></footer-cmp>
      </div>
  </div>

</div>
