import { Component, OnInit } from '@angular/core';
import { LocationStrategy, PlatformLocation, Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  logOut: boolean;
  defaultHome: boolean;

    constructor(public location: Location, private _http: HttpClient, private spinner: NgxSpinnerService) {
        this._http.get('assets/APIURL.txt', { responseType: 'text' })
            .subscribe(data => localStorage.setItem('apiurl', data));

        this._http.get('assets/IMAGEURL.txt', { responseType: 'text' })
            .subscribe(data => localStorage.setItem('imageurl', data));
    }

    ngOnInit(){
    }
    startSpinner() {
            this.spinner.show();
    }
    stopSpinner() {
        this.spinner.hide();
    }
    showNotification(from, align, errMsg, colors) {
        const type = ['', colors];

        var color = Math.floor((Math.random() * 1) + 1);
        $.notify({
            message: errMsg
        }, {
            type: type[color],
            timer: 1000,
            placement: {
                from: from,
                align: align
            }
        });
    }
    isMap(path){
      var titlee = this.location.prepareExternalUrl(this.location.path());
      titlee = titlee.slice( 1 );
      if(path == titlee){
        return false;
      }
      else {
        return true;
      }
    }
    
}
