
<div class="sidebar-wrapper">
    <div class="logo">
        <a  class="simple-text">
          EMS
        </a>
    </div>



    <ul class="nav responsive-nav">
        <li class="separator hidden-lg hidden-md" *ngIf="isMobileMenu()"></li>
        <li routerLinkActive="active" class="">
            <a [routerLink]="['/dashboard']">
                <i class="pe-7s-graph"></i>
                <p style="font-size: 15px;">HOME</p>
            </a>
        </li>


    </ul>
                </div>
