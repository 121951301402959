import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { routing } from './app.routing';
import { NavbarModule } from './shared/navbar/navbar.module';
import { FooterModule } from './shared/footer/footer.module';
import { SidebarModule } from './sidebar/sidebar.module';
import { HttpModule } from '@angular/http';
import { AppComponent } from './app.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './login/login.component';
import { AuthenticationService } from 'app/services/authentication.service';
import { AuthguardGuard } from './authguard.guard';
import { HomeService } from './home/home.service';
import {GlobalVariable} from 'app/services/rootapiurl';
import { ViewimageComponent } from './viewimage/viewimage.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PasswordmanagementComponent } from './passwordmanagement/passwordmanagement.component';
import { PasswordmanagementService } from './passwordmanagement/passwordmanagement.service';




@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    RouterModule,
    HttpClientModule,
    HttpModule,
    NavbarModule,
    FooterModule,
    SidebarModule,
    routing,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    NgxSpinnerModule
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,
    ViewimageComponent,
    PasswordmanagementComponent,
      
  ],
  providers: [
    AuthenticationService,
    AuthguardGuard,
    HomeService,
    GlobalVariable,
    PasswordmanagementService,
  ],
    
  bootstrap: [AppComponent]
})
export class AppModule { }
