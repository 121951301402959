import { Injectable } from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
//import {UserService} from './user.service'

@Injectable()
export class AuthguardGuard implements CanActivate {
  constructor(private router: Router){

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    
    if (localStorage.getItem('accessToken')) {
        // this.router.navigate(['dashboard']);
        return true;
    }else{
        this.router.navigate(['login']);
        return false;
    }
}
}
