import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class GlobalVariable {
  
  public apiUrl;
  public imageUrl;
  constructor(private _http: HttpClient) { 
  //  this._http.get('assets/APIURL.txt', { responseType: 'text' })
  //.subscribe(data =>localStorage.setItem('apiurl',data));

  //this._http.get('assets/IMAGEURL.txt', { responseType: 'text' })
  //.subscribe(data => localStorage.setItem('imageurl',data));

 }
}


