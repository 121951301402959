import { Injectable } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { Http, Response } from '@angular/http';

@Injectable()
export class HomeService {

    private Url = localStorage.getItem('apiurl');
    constructor(private http: Http, private authService: AuthenticationService, ) { }
    private handleError(error: any) {
        //let errMsg=error;
        let errMsg = error.json();
        return Observable.throw(errMsg);
    }
    private extractData(res: Response) {
        let body = res.json();
        return body || [];
    }
    getEmailConfigDetailsService(): Observable<any> {
        let getUrl = this.Url + 'Masters/getAllEmailConfigurationDetails';
        return this.http.get(getUrl, {
            headers: this.authService.getAuthHeaders()
        }).map(this.extractData).catch(this.handleError);

    }
    getAllProjectListService(): Observable<any> {
        let getUrl = this.Url + 'Masters/getAllProjectDetails';
        return this.http.get(getUrl, {
            headers: this.authService.getAuthHeaders()
        }).map(this.extractData).catch(this.handleError);

    }
    SaveEmailSettingDetailsService(configDetail) {
        let postUrl = this.Url + 'Masters/insertEmailConfigurationDetails';
        return this.http.post(postUrl, configDetail,{
            headers: this.authService.getAuthHeaders()
        }).catch(this.handleError);

    }
    SaveProjectDetailsService(newProjectDetail) {
        let postUrl = this.Url + 'Masters/saveProjectDetails';
        return this.http.post(postUrl, newProjectDetail, {
            headers: this.authService.getAuthHeaders()
        }).catch(this.handleError);

    }
    DeleteProjectDetailsService(id) {
        let postUrl = this.Url + 'Masters/deleteProjectDetails/' + id;
        return this.http.post(postUrl, {
            headers: this.authService.getAuthHeaders()
        }).catch(this.handleError);

    }
    DeleteEmailSettingDetailsService(id) {
        let postUrl = this.Url + 'Masters/deleteEmailSettingDetails/' + id;
        return this.http.post(postUrl, {
            headers: this.authService.getAuthHeaders()
        }).catch(this.handleError);

    }
}
