import { Component, OnInit } from '@angular/core';
import { PasswordmanagementService } from './passwordmanagement.service';
import { AppComponent } from '../app.component';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-passwordmanagement',
  templateUrl: './passwordmanagement.component.html',
  styleUrls: ['./passwordmanagement.component.scss']
})
export class PasswordmanagementComponent implements OnInit {
    data: any = {};
    showSuccessMsg: boolean;
    showFirstForm: boolean=true;
    showSecondForm: boolean=false;
    showThirdForm: boolean = false;
    cnfpwd: any;
    pwd: any;
    checkpassword: boolean;
    constructor(private router: Router,private http: HttpClient, private appComponent: AppComponent,
        private passwordmanagementservice: PasswordmanagementService) { }

  ngOnInit() {
  }
    openmodel() {
        $("#message").css("display", "block");
        $("#message").css("margin-top", "15%");
        $(".body-content").css("filter", "blur(4px)");
        $(".bg").css("filter", "blur(4px)");
        $(".subhead").css("filter", "blur(4px)");
    }
    closemessage() {
        $("#message").css("display", "none");
        $(".bg").css("filter", "blur(0px)");
        $(".body-content").css("filter", "blur(0px)");
        $(".subhead").css("filter", "blur(0px)");
    }
    goToLoginPage() {
        this.closemessage();
        this.router.navigate(['login']);
    }
    getpassword(password: string) {
        this.pwd = password;
    }
    checkSame(password: string) {
        this.cnfpwd = password;
        if (this.cnfpwd == this.pwd) {
            // stuff for form control
            this.checkpassword = false;
        }
        else {
            this.checkpassword = true;
            // form control with errors
        }
    }
    sendOtp(resetPasswordParm: any) {
        this.appComponent.startSpinner();
        return this.passwordmanagementservice.sendOtpService(resetPasswordParm)
            .subscribe(
                data => {
                    if (data.status == 200) {
                        this.showFirstForm = false;
                        this.showSecondForm = true;
                        this.showThirdForm = false;
                    }
                    this.appComponent.stopSpinner();
                }, (err) => {
                    this.appComponent.stopSpinner();
                    this.appComponent.showNotification('bottom', 'right', err.Message, 'danger');
                }
            )
    }

    verifyOtp(resetPasswordParm: any) {
        this.appComponent.startSpinner();
        return this.passwordmanagementservice.verifyOtpService(resetPasswordParm)
            .subscribe(
                data => {
                    if (data.status == 200) {
                        this.showFirstForm = false;
                        this.showSecondForm = false;
                        this.showThirdForm = true;
                    }
                    this.appComponent.stopSpinner();
                }, (err) => {
                    this.appComponent.stopSpinner();
                    this.appComponent.showNotification('bottom', 'right', err.Message, 'danger');
                }
            )
    }
    resetPassword(resetPasswordParm: any) {
        this.appComponent.startSpinner();
        return this.passwordmanagementservice.resetPasswordService(resetPasswordParm)
            .subscribe(
                data => {
                    if (data.status == 200) {
                        this.showFirstForm = false;
                        this.showSecondForm = false;
                        this.showThirdForm = true;
                        this.openmodel();
                    }
                    this.appComponent.stopSpinner();
                }, (err) => {
                    this.appComponent.stopSpinner();
                    this.appComponent.showNotification('bottom', 'right', err.Message, 'danger');
                }
            )
    }
}
