import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { HttpClient } from '@angular/common/http';
//import{ODCAppComponent} from '../odcapp/odcapp.component'
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map'

@Injectable()
export class AuthenticationService {
    private extractData(res: Response) {
        let body = res.json();
    return  body || [];
   }
   
   private handleError (error: any) {  
       let errMsg = error.json();
       console.error(errMsg); // log to console instead
       return Observable.throw(errMsg);    
   }

    constructor(private http: Http, private _http: HttpClient) {
       
      
     }
    users:any;
    private URL = localStorage.getItem('apiurl');
   
    login(email: string, password: string) {
        //alert(this.URL);
       let loginUrl=this.URL+'Account/login';
      //alert(loginUrl)
        let formData:FormData = new FormData();
        formData.append('applicationno',email);
        formData.append('password',password);
      //  alert(localStorage.getItem('apiurl'));
        return this.http.post(loginUrl,formData)
            .map((response: Response) => {
                let user = response.json();   
                if(user.isEnable)                  
                   {
                    this.users = user;
                    //alert(user.name);
                    localStorage.setItem('userid', user.userid);
                    localStorage.setItem('registrationid', user.applicationno)
                    localStorage.setItem('Name', user.name);
                    localStorage.setItem('phNumber', user.phoneNumber);
                    localStorage.setItem('Email', user.email);
                    localStorage.setItem('roleName', user.role);
                    localStorage.setItem('status',user.isEnable); 
                    localStorage.setItem('accessToken',user.token);               
                   }
                   return user;         
            }).catch(this.handleError);            
    }
    // logout() {
    //     // remove user from local storage to log user out
    //     localStorage.clear();
    //     }
    changeUser(user:any){
        let emailUrl=this.URL+'UserManagement/forgetpassword/'+user;
        return this.http.get(emailUrl).catch(this.handleError);
    }
    checkToken(token:any){
        let emailUrl=this.URL+'UserManagement/checkvalidtoken/'+token;
        return this.http.get(emailUrl).catch(this.handleError);
    }
    sendResetPassword(resetData,token,checkToken){
        let formData: FormData = new FormData();
        formData.append("password", resetData.newPassword);
        formData.append("c_password", resetData.confirmPassword);
        formData.append("token", token);
        formData.append("email", checkToken);

        let postUrl = this.URL+'UserManagement/forgotpassword';
        return this.http.post(postUrl, formData).catch(this.handleError);
    }
    getAuthHeaders(){
      return  new Headers({'Authorization': 'Bearer ' + localStorage.getItem("accessToken")});
    }
}